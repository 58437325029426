import moment from 'moment';
import { useIntl } from 'src/domains/i18n';
import { LessonCard } from 'src/features/group-lessons/components/lesson-card';
import { groupClassUrl } from 'src/features/group-lessons/config/group-lessons';
import { Button } from 'src/features/shared/components/button';
import { useGroupLessons } from 'src/features/shared/hooks/use-group-lessons';
import { ButtonType } from 'src/features/shared/types/enums/ButtonType';
import { ButtonVariant } from 'src/features/shared/types/enums/ButtonVariant';
import { GA4_EVENTS, pushGA4Event } from 'src/features/shared/utils/ga4';
import styles from '../index.module.scss';
import {
  FontColor,
  H4,
  Small,
} from 'src/features/shared/components/typography';

export interface IDayLessonsProps {
  isPanel?: boolean;
  onExpandClick: () => void;
  pageSize: number;
  clubId: number;
  slug: string;
}

export function DayLessons({
  isPanel,
  onExpandClick,
  pageSize,
  slug,
  clubId,
}: IDayLessonsProps) {
  const { formatMessage } = useIntl();
  const maxLessons = isPanel ? 30 : 20;
  const { availableLessons, hasMoreLessons } = useGroupLessons(
    clubId.toString(),
    pageSize,
  );

  const getDayMonthDisplayString = (date: string) => {
    const monthString = moment(date, 'YYYY-MM-DD')
      .locale('en-gb')
      .format('MMMM')
      .toLowerCase();

    const month = formatMessage(`general.months.${monthString}`);
    return `${moment(date, 'YYYY-MM-DD').format('D')} ${month}`;
  };

  const showLink = pageSize === maxLessons || !hasMoreLessons;
  const buttonProps = showLink
    ? {
        openInNewTab: isPanel,
        href: `/${slug}${groupClassUrl}`,
        onClick: () => {
          pushGA4Event(GA4_EVENTS.trackEvent, {
            event_name: 'click_cta_grouplesson',
          });
        },
      }
    : {
        onClick: () => {
          pushGA4Event(GA4_EVENTS.trackEvent, {
            event_name: 'click_more_grouplesson',
          });
          onExpandClick();
        },
      };

  return (
    <>
      <H4 className={styles.lessonsTitle}>
        {formatMessage('club-detail.group-lessons.lessons-label')}
      </H4>
      {availableLessons.map(({ date, lessons }) => (
        <div key={date}>
          <Small className={styles.dateDescription} color={FontColor.Neutral03}>
            {getDayMonthDisplayString(date)}
          </Small>
          {lessons.map((lesson) => (
            <LessonCard
              {...lesson}
              hasDarkBackground
              key={`${lesson.name}${lesson.startTime}`}
              image={lesson.imageUrl}
              instructor={lesson.instructor ?? ''}
              isPanel={isPanel}
            />
          ))}
        </div>
      ))}
      <div className={styles.buttonContainer}>
        <Button
          isFullWidth
          variant={ButtonVariant.Inverted}
          type={ButtonType.Button}
          {...buttonProps}
        >
          {formatMessage(
            showLink
              ? 'club-detail.group-lessons.view-full-lessons'
              : 'club-detail.group-lessons.view-more',
          )}
        </Button>
      </div>
    </>
  );
}
