import { getGroupLessonsData } from '@features/group-lessons/services/proxy'
import { groupLessonsKeys } from '@features/shared/react-query-keys/group-lessons'
import { useQuery } from 'react-query'
import { ILessons } from '../../group-lessons/types/ILessons'
import { STALE_TIME } from '../constants/staleTime'
import { ILesson } from 'src/features/group-lessons/types/ILesson'
import { useEffect, useState } from 'react'

export interface ILessonsByDay {
    date: string
    lessons: ILesson[]
}
interface IUseGroupLessonsReturn {
  availableLessons: ILessonsByDay[]
  lessons: ILessons | never[]
  hasMoreLessons: boolean
}

export const useGroupLessons = (clubId: string, pageSize = 5) : IUseGroupLessonsReturn => {
  const [ availableLessons, setAvailableLessons ] = useState<ILessonsByDay[]>([])
  const [ hasMoreLessons, setHasMoreLessons ] = useState(false)

  const { data: lessons } = useQuery<ILessons, Error>(
    groupLessonsKeys.list(clubId),
    async () => {
      const res = await getGroupLessonsData({ clubId })
      return res.data
    },
    {
      staleTime: STALE_TIME,
      cacheTime: STALE_TIME * 1.5
    }
  )

  useEffect(() => {
    if (!lessons) {
      setAvailableLessons([])
      setHasMoreLessons(false)
      return
    }

    const availableDates = Object.keys(lessons).filter((item) => lessons[item].length > 0)

    let totalItems = 0
    availableDates.forEach((date) => {
      totalItems = totalItems + lessons[date].length
    })

    let length = 0
    const newAvailableLessons = []

    for (const i in availableDates) {
      const date = availableDates[i]
      const dateLessons = lessons[date].slice(0, pageSize - length)

      newAvailableLessons.push({
        date,
        lessons: dateLessons
      })

      length = length + dateLessons.length

      if (length === pageSize) {
        break
      }
    }
    setAvailableLessons(newAvailableLessons)
    setHasMoreLessons(totalItems > pageSize)
  }, [ lessons, pageSize ])

  return {
    lessons: lessons || [],
    availableLessons,
    hasMoreLessons
  }
}
