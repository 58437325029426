import { ILessons } from '@features/group-lessons/types/ILessons'
import { GroupLessonsData } from '../proxy'

export const mapGroupLessonData = (lessons: GroupLessonsData) => {
  const groupLessons: ILessons = {}

  Object.keys(lessons).forEach((key) => {
    const day = lessons[key].map(({
      name, description, instructor, club, room, ...lesson
    }) => ({
      name,
      description,
      instructor,
      club,
      room,
      duration: lesson.duration_minutes,
      imageUrl: lesson.image_url,
      startTime: lesson.start_time,
      endTime: lesson.end_time,
      infoUrl: lesson.info_url
    }))

    groupLessons[key] = day
  })

  return groupLessons
}
