import styles from './index.module.scss';
import Icons from 'features/shared/components/icons';
import classNames from 'classnames';
import { MouseEventHandler } from 'react';
import { Button } from 'src/features/shared/components/button';
import { Body } from 'src/features/shared/components/typography';
import { ButtonVariant } from 'src/features/shared/types/enums/ButtonVariant';

interface Props {
  title: string;
  href: string;
  linkText: string;
  iconInfo?: string;
  isAlternative?: boolean;
  paddingSmall?: boolean;
  onClick?: MouseEventHandler;
}

const Request = ({
  title,
  href,
  linkText,
  iconInfo,
  isAlternative,
  paddingSmall,
  onClick,
}: Props) => {
  return (
    <div
      className={classNames(
        styles.container,
        { [styles.alternative]: isAlternative },
        { [styles['padding-small']]: paddingSmall },
      )}
    >
      {iconInfo ? (
        <Icons.InfoCircleFilled className={styles.icon} />
      ) : (
        <Icons.Question className={styles.icon} />
      )}
      <Body>
        {title}
        <Button
          href={href}
          className={styles.contact}
          variant={ButtonVariant.Link}
          openInNewTab={false}
          onClick={onClick}
        >
          {linkText}
        </Button>
      </Body>
    </div>
    // </Section>
  );
};

export default Request;
