import { ProxyClient } from '@services/proxy/client'
import { GroupLessonsQueryProps } from '@features/group-lessons/services/electrolyte'
import { mapGroupLessonData } from '../mappers/map-group-lesson-data'

export interface GroupLessonsData {
  [key: string]: {
    'name': string | null,
    'description': string | null,
    'image_url': string | null,
    'start_time': string | null,
    'end_time': string | null,
    'duration_minutes': number | null,
    'instructor': string | null,
    'club': string | null,
    'room': string | null,
    'info_url': string | null
  }[]
}

export async function getGroupLessonsData ({ clubId, date, days }: GroupLessonsQueryProps) {
  const { data } = await ProxyClient.get<GroupLessonsData>(`/group-lessons/${clubId}`, { params: { date, days } })

  return { data: mapGroupLessonData(data) }
}
