import { Badge } from 'src/features/shared/components/badge';
import Icons from 'features/shared/components/icons';
import {Image} from 'features/shared/components/image';
import { useIntl } from '@domains/i18n';
import { GroupLessonsDetails } from '@features/group-lessons/components/details';
import { useSheet } from '@features/shared/hooks/use-sheet';
import useBreakpoints from '@utils/hooks/use-breakpoints';
import classNames from 'classnames';
import moment from 'moment-timezone';
import styles from './index.module.scss';
import { GA4_EVENTS, pushGA4Event } from 'src/features/shared/utils/ga4';
import {
  Body,
  FontColor,
  FontWeight,
} from 'src/features/shared/components/typography';

interface ILessonCardProps {
  image: string | null;
  startTime: string | null;
  endTime: string | null;
  name: string | null;
  duration: number | null;
  room: string | null;
  instructor: string | null;
  description: string | null;
  infoUrl: string | null;
  hasDarkBackground?: boolean;
  isPanel?: boolean;
}

export const LessonCard = ({
  image,
  startTime,
  endTime,
  duration,
  name,
  instructor,
  room,
  description,
  infoUrl,
  hasDarkBackground = false,
  isPanel,
}: ILessonCardProps) => {
  const { formatMessage } = useIntl();
  const sheet = useSheet();

  const start = moment(startTime);
  const end = moment(endTime);

  const formattedStartTime = start.format('HH:mm');
  const formattedEndTime = end.format('HH:mm');
  const isCurrentTime = moment().isBetween(start, end);

  const electrolyteUrl = process.env.NEXT_PUBLIC_PAYMENT_APP_URL;
  const emptyImage = `${electrolyteUrl}/images/empty_badge@3x.png`;
  const placeholder = {
    src: '/static/images/sportcity/illustrations/fallback-thumb.svg',
    alt: 'placeholder',
  };
  const lessonImage =
    image !== emptyImage
      ? { src: image, alt: `groepsles ${name}` }
      : { src: placeholder.src, alt: placeholder.alt };

  const { currentBreakpoint, breakpoints } = useBreakpoints();
  const isMobile = currentBreakpoint === breakpoints.xs.name;

  const toggleDetails = (e: React.MouseEvent<HTMLElement>) => {
    if (!isPanel) {
      pushGA4Event(GA4_EVENTS.trackEvent, {
        event_name: 'click_details_grouplesson',
      });
      sheet.toggle();
      e.preventDefault();
    }
  };

  return (
    <>
      <GroupLessonsDetails
        startTime={formattedStartTime}
        endTime={formattedEndTime}
        image={image}
        name={name}
        duration={duration}
        room={room}
        instructor={instructor}
        description={description}
        infoUrl={infoUrl}
        sheet={sheet}
      />
      <div
        className={classNames(
          styles['list-item'],
          { [styles.hasDarkBackground]: hasDarkBackground },
          isPanel && styles.isPanel,
        )}
        role="button"
        data-testid="lesson-card-clickable"
        onClick={toggleDetails}
      >
        <div className={styles['image-container']}>
          <Image
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            image={lessonImage}
            className={styles.image}
          />
        </div>
        <div className={styles.details}>
          <div className={styles.line}>
            <Body fontWeight={FontWeight.Bold} typographyStyle="h5">
              {formattedStartTime} - {formattedEndTime}
            </Body>
            {name && !isMobile ? (
              <Body
                className={styles.truncate}
                typographyStyle="h5"
                fontWeight={FontWeight.Bold}
              >
                {name}
              </Body>
            ) : null}
            {isCurrentTime && !isMobile && (
              <Badge
                isTertiary
                label={formatMessage('group-lessons.lesson-item.ongoing')}
              />
            )}
          </div>
          {isMobile && (
            <div className={styles.line}>
              {name ? (
                <Body
                  className={styles.truncate}
                  typographyStyle="h5"
                  fontWeight={FontWeight.Bold}
                >
                  {name}
                </Body>
              ) : null}
              {isCurrentTime && isMobile && (
                <Badge
                  isTertiary
                  label={formatMessage('group-lessons.lesson-item.ongoing')}
                />
              )}
            </div>
          )}
          <div className={classNames(styles.line, styles['secondary-details'])}>
            {duration && duration > 0 && (
              <Body color={FontColor.Neutral02}>
                {formatMessage('group-lessons.lesson-item.duration', {
                  duration,
                })}
              </Body>
            )}
            {instructor && (
              <Body color={FontColor.Neutral02}>{instructor}</Body>
            )}
            {room && (
              <Body className={styles.truncate} color={FontColor.Neutral02}>
                {room}
              </Body>
            )}
          </div>
        </div>
        {!isPanel && <Icons.ArrowRight className={styles.icon} />}
      </div>
    </>
  );
};
