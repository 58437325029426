import Icons from 'features/shared/components/icons';
import styles from './index.module.scss';
import theme from '@config/theme';
import { useIntl } from '@domains/i18n';
import { ACTIVE_FUNNELS } from '@features/pg-funnel/config/active-funnels';
import { Image } from '@features/shared/components/image';
import { Sheet } from '@features/shared/components/sheet';
import { IUseSheetReturn } from '@features/shared/hooks/use-sheet';
import { Button } from 'src/features/shared/components/button';
import { ButtonVariant } from 'src/features/shared/types/enums/ButtonVariant';
import { GA4_EVENTS, pushGA4Event } from 'src/features/shared/utils/ga4';
import Request from '../request';
import { Body, FontColor, H2 } from 'src/features/shared/components/typography';

interface ILessonCardDetails {
  image: string | null;
  startTime: string | null;
  endTime: string | null;
  name: string | null;
  duration: number | null;
  room: string | null;
  instructor: string | null;
  description: string | null;
  infoUrl: string | null;
  sheet: IUseSheetReturn;
}

export const GroupLessonsDetails = ({
  image,
  startTime,
  endTime,
  name,
  duration,
  room,
  instructor,
  description,
  infoUrl,
  sheet,
}: ILessonCardDetails) => {
  const { formatMessage } = useIntl();
  const dayPassUrl = `/funnel/${ACTIVE_FUNNELS.sportcity.DAY_PASS}`;
  const buttonUrl = `/funnel/${ACTIVE_FUNNELS.sportcity.DEFAULT}`;

  const electrolyteUrl = process.env.NEXT_PUBLIC_PAYMENT_APP_URL;
  const emptyImage = `${electrolyteUrl}/images/empty_badge@3x.png`;
  const placeholder =
    '/static/images/sportcity/illustrations/fallback-large.svg';
  const lessonImage = image !== emptyImage ? image : placeholder;

  return (
    <Sheet
      sheet={sheet}
      position="right"
      headerImage={{ src: lessonImage || emptyImage, alt: 'lesson-header' }}
    >
      <Image
        className={styles.logo}
        image={{
          src: '/static/images/sportcity/illustrations/grouplessons-details-logo.svg',
          alt: 'logo',
        }}
      />
      <div>
        <div className={styles.content}>
          <H2 className={styles.title}>{name ?? ''}</H2>
          <Body className={styles.time}>
            {startTime} - {endTime}
          </Body>
          <Body color={FontColor.Neutral02}>
            {duration &&
              duration > 0 &&
              formatMessage('group-lessons.lesson-item.duration-class', {
                duration,
              })}
            {instructor &&
              formatMessage('group-lessons.lesson-item.instructor', {
                instructor,
              })}
            {room && formatMessage('group-lessons.lesson-item.room', { room })}
          </Body>
          <Request
            iconInfo="InfoCircleFilled"
            title={formatMessage('group-lessons.reserve')}
            href={theme.app.website}
            linkText={formatMessage('home.app-download.app')}
            isAlternative
            paddingSmall
            onClick={() =>
              pushGA4Event(GA4_EVENTS.trackEvent, {
                event_name: 'download_app_sidepanel_club',
              })
            }
          />
          <Body color={FontColor.Neutral02} className={styles.details}>
            {description ?? ''}
          </Body>
          {infoUrl && (
            <Button
              icon={<Icons.ArrowRight />}
              href={infoUrl}
              variant={ButtonVariant.Link}
            >
              {formatMessage('group-lessons.more-info', { name })}
            </Button>
          )}
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            openInNewTab
            href={buttonUrl}
            onClick={() =>
              pushGA4Event(GA4_EVENTS.trackEvent, {
                event_name: 'click_cta_sidepanel_primary',
              })
            }
          >
            {formatMessage('join')}
          </Button>
          <Button
            openInNewTab
            href={dayPassUrl}
            variant={ButtonVariant.Inverted}
            onClick={() =>
              pushGA4Event(GA4_EVENTS.trackEvent, {
                event_name: 'click_cta_sidepanel_secondary',
              })
            }
          >
            {formatMessage('day-pass.request')}
          </Button>
        </div>
      </div>
    </Sheet>
  );
};
