import { useRichTextRenderer } from 'src/features/shared/hooks/useRichTextRenderer';
import { useIntl } from '@domains/i18n';
import { groupClassUrl } from '@features/group-lessons/config/group-lessons';
import { DescriptionTypography } from '@features/shared/components/description-typography';
import {
  Section,
  Container,
  Col,
  Row,
} from '@features/shared/components/layout';
import { EntryFields } from 'contentful';
import { useState } from 'react';
import { Button } from 'src/features/shared/components/button';
import { ButtonType } from 'src/features/shared/types/enums/ButtonType';
import { ButtonVariant } from 'src/features/shared/types/enums/ButtonVariant';
import { Anchor } from '../club-details/anchor-links/anchor';
import { DayLessons } from './day-lessons';
import styles from './index.module.scss';
import classNames from 'classnames';
import { GA4_EVENTS, pushGA4Event } from 'src/features/shared/utils/ga4';
import { ClubPageSectionHeading } from 'src/features/shared/components/typography';

export interface IGroupLessonsProps {
  slug: string;
  clubId: number;
  anchorLinkTitle: string;
  description?: EntryFields.RichText;
  isPanel?: boolean;
}

export function GroupLessons({
  slug,
  clubId,
  anchorLinkTitle,
  description,
  isPanel,
}: IGroupLessonsProps) {
  const { formatMessage } = useIntl();
  const { renderRichText } = useRichTextRenderer();
  const [pageSize, setPageSize] = useState(5);

  return (
    <Section forceMobileView={isPanel}>
      <Anchor title={anchorLinkTitle} />
      <Container forceMobileView={isPanel}>
        <Row noGap>
          <Col
            columns={isPanel ? 'xs:12' : 'xs:12 lg:6'}
            className={styles.descriptionContainer}
          >
            <div className={styles.titleWrapper}>
              <ClubPageSectionHeading forceMobileView={isPanel}>
                {formatMessage('group-lessons.entry-point.title')}
              </ClubPageSectionHeading>
            </div>
            <Row noGap>
              <Col columns={`xs:12 ${isPanel ? 'lg:12' : 'lg:9'}`}>
                <DescriptionTypography
                  className={classNames(
                    styles.description,
                    !isPanel && styles.marginBottomMd,
                  )}
                >
                  <p>
                    {description ? (
                      <>{renderRichText(description)}</>
                    ) : (
                      formatMessage('club-detail.group-lessons.description')
                    )}
                  </p>
                </DescriptionTypography>
              </Col>
              {!isPanel && (
                <Col columns="xs:9 sm:8 lg:9">
                  <Button
                    variant={ButtonVariant.Secondary}
                    dataTestId="view-all-button"
                    type={ButtonType.Button}
                    onClick={() => {
                      pushGA4Event(GA4_EVENTS.trackEvent, {
                        event_name: 'click_cta_grouplesson',
                      });
                    }}
                    href={`/${slug}${groupClassUrl}`}
                  >
                    {formatMessage(
                      'club-detail.group-lessons.view-full-lessons',
                    )}
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
          <Col
            columns={isPanel ? 'xs:12' : 'xs:12 lg:6'}
            className={styles.dayLessonsCol}
          >
            <DayLessons
              slug={slug}
              isPanel={isPanel}
              clubId={clubId}
              pageSize={pageSize}
              onExpandClick={() => setPageSize(pageSize + 5)}
            />
          </Col>
        </Row>
      </Container>
    </Section>
  );
}
